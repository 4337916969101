<template>
  <div id="company" class="tab-pane">
    <h6 class="tx-uppercase tx-semibold tx-color-01 mg-b-0 word-space-4">Organization Settings</h6>
    <hr class="mg-t-4" />
    <div class="form-settings">
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label mb-0">Logo *</div>
        <div class="col-md-8 m-0 p-0">
          <input
            type="file"
            id="brand_logo"
            style="display: none"
            @change="base64Image"
          />
          <label
            for="brand_logo"
            class="logo-container"
          >
            <span v-if="!formData.logo"
              >Browse <br />
              Image</span
            >
            <div class="brand_logo_img">
              <img
                :src="
                    formData.logo
                  "
                alt=""
              />
              
            </div>
            <span id="change_logo_img" v-if="formData.logo">Change <br> Image</span>
          </label>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Organization Name *</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" v-model="formData.name" class="form-control"/>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Name of Your Organization, This will be included and printed in any Generated Slips and Reports
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Address *</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" v-model="formData.address" class="form-control"/>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Your Company Address, This would not used anywhere
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">VAT / PAN No.</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" v-model="formData.pan_vat" class="form-control"/>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            PAN / VAT No. of Your Organization, This will be included and printed in any Generated Slips and Reports
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Phone No.</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" v-model="formData.phone" class="form-control"/>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Your Company Contact No. This would not used anywhere
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Email</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" v-model="formData.email" class="form-control"/>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Your Company Email Id, This would not used anywhere
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Website</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" v-model="formData.website" class="form-control"/>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Your Company Website, This would not used anywhere
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Overview</div>
        <div class="col-md-8 ml-0 pl-0">
          <textarea  v-model="formData.overview" rows="3" class="form-control"></textarea>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Short detail about your Company, This would not used anywhere
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right mt-2 pr-0">
      <button @click="submit" class="btn btn-sm btn-primary">Submit</button>
    </div>
      <!-- row -->
    </div>
  </div>
  <!-- tab-pane -->
</template>
<script>
/* eslint-disable */
import {mapGetters} from "vuex";
export default {
  data(){
    return{
      formData:{
        'id':null,
        'name':null,
        'address':null,
        'pan_vat':null,
        'phone':null,
        'email':null,
        'website':null,
        'overview':null
      }
    }
  },
  computed: {
    ...mapGetters(["eventMessage","dataLists",'currentComponent']),
  },
  methods:{
    // convert image into url
    base64Image(e) {
      this.formData.logo = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.logo = e.target.result;
        this.submit();
      };
    },
    submit(){
      if(this.formData.id){
        this.$store.commit("setApiUrl", `api/settings/organization/${this.formData.id}`);
        this.$store.commit("patchData", this.formData);
      }else{
        this.$store.commit("setApiUrl", "api/settings/organization");
        this.$store.commit("sendData", this.formData);
      }
    },
    getData(){
      this.$store.commit("getData", "api/settings/organization");
    }
  },watch:{
    currentComponent(value){
      if (value=='companySetting'){this.getData();}
    },
    eventMessage(value){
      if (value.indexOf('created success')>=0 && this.currentComponent=='companySetting'){this.getData();}
    },
    dataLists(value){
      if (value.length!=0){
        this.formData={...this.dataLists};
      }
    }
  },destroyed() {
    this.formData.id=null;
  }
}
</script>