<template>
  <div id="lead" class="tab-pane">
    <div class="row">
      <div class="col-lg-6">
        <h5 class="word-space-4 mt-1">Manage Status</h5>
      </div>
      <div class="col-sm-0 tx-right col-lg-6">
        <button type="button" class="btn btn-xs btn-primary mt-0 mr-0 mb-2"
        @click="$store.dispatch('modalWithMode', { id: 'leadStatus', mode: 'create'})">
          Add Status
        </button>
      </div>
    </div>
    <div class="bg-theam-primary">
      <table class="table table1 table-hover" id="exampleTable">
        <thead>
          <tr>
            <th class="wd-10p table-start-item">S.N</th>
            <th class="wd-30p">Status Title</th>
            <th class="wd-20p">Color</th>
            <th class="wd-20p">Status</th>
            <th class="wd-20p text-right table-end-item">Action</th>
          </tr>
        </thead>
        <tbody v-for="(leadProperty, index) in dataLists.property" :key="index">
          <tr v-if="leadProperty.type == '2'">
            <th scope="row" class="table-start-item">1</th>
            <td>{{leadProperty.title}}</td>
            <td>{{leadProperty.color}}</td>
            <td><span v-if="leadProperty.status == 1">Active</span><span v-else>Inactive</span>
            <td class="text-right table-end-item">
              <a href="javascript:;" class="mr-3">
                <i class="fa fa-edit" @click="edit('leadStatus', leadProperty.id)"></i>
              </a>
              <a href="javascript:;">
                <i class="fa fa-trash" @click="drop(leadProperty.id)"></i>
              </a>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-6">
        <h5 class="word-space-4 mt-1">Manage Todo</h5>
      </div>
      <div class="col-sm-0 tx-right col-lg-6">
        <button
          type="button"
          class="btn btn-xs btn-primary mt-0 mr-0 mb-2"
          @click="$store.dispatch('modalWithMode', { id: 'leadTodo', mode: 'create'})"
        >
          Add Todo
        </button>
      </div>
    </div>
    <div class="bg-theam-primary">
      <table class="table table1 table-hover" id="exampleTable">
        <thead>
          <tr>
            <th class="wd-10p table-start-item">S.N</th>
            <th class="wd-30p">Todo Title</th>
            <th class="wd-20p">Color</th>
            <th class="wd-20p">Status</th>
            <th class="wd-20p text-right table-end-item">Action</th>
          </tr>
        </thead>
        <tbody v-for="(leadProperty, index) in dataLists.property" :key="index">
          <tr v-if="leadProperty.type == '4'">
            <th scope="row" class="table-start-item">1</th>
            <td>{{leadProperty.title}}</td>
            <td>{{leadProperty.color}}</td>
            <td><span v-if="leadProperty.status == 1">Active</span><span v-else>Inactive</span></td>
            <td class="text-right table-end-item">
              <a href="javascript:;" class="mr-3">
                <i class="fa fa-edit" @click="edit('leadTodo', leadProperty.id)"></i>
              </a>
              <a href="javascript:;">
                <i class="fa fa-trash" @click="drop(leadProperty.id)"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-6">
        <h5 class="word-space-4 mt-1">Manage Source</h5>
      </div>
      <div class="col-sm-0 tx-right col-lg-6">
        <button type="button" class="btn btn-xs btn-primary mt-0 mr-0 mb-2"
        @click="$store.dispatch('modalWithMode', { id: 'leadSource', mode: 'create'})">
          Add Source
        </button>
      </div>
    </div>
    <div class="bg-theam-primary">
      <table class="table table1 table-hover" id="exampleTable">
        <thead>
          <tr>
            <th class="wd-10p table-start-item">S.N</th>
            <th class="wd-35p">Source Title</th>
            <th class="wd-35p">Status</th>
            <th class="wd-20p text-right table-end-item">Action</th>
          </tr>
        </thead>
        <tbody v-for="(leadProperty, index) in dataLists.property" :key="index">
          <tr v-if="leadProperty.type == '3'">
            <th scope="row" class="table-start-item">1</th>
            <td>{{leadProperty.title}}</td>
            <td><span v-if="leadProperty.status == 1">Active</span><span v-else>Inactive</span></td>
            <td class="text-right table-end-item">
              <a href="javascript:;" class="mr-3">
                <i class="fa fa-edit" @click="edit('leadSource', leadProperty.id)"></i>
              </a>
              <a href="javascript:;">
                <i class="fa fa-trash" @click="drop(leadProperty.id)"></i>
              </a>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-6">
        <h5 class="word-space-4 mt-1">Manage Priority</h5>
      </div>
      <div class="col-sm-0 tx-right col-lg-6">
        <button type="button" class="btn btn-xs btn-primary mt-0 mr-0 mb-2" 
        @click="$store.dispatch('modalWithMode', { id: 'LeadPriority', mode: 'create'})">
          Add Priority
        </button>
      </div>
    </div>
    <div class="bg-theam-primary">
      <table class="table table1 table-hover" id="exampleTable">
        <thead>
          <tr>
            <th class="wd-10p table-start-item">S.N</th>
            <th class="wd-30p">Priority Title</th>
            <th class="wd-20p">Percentage</th>
            <th class="wd-20p">Status</th>
            <th class="wd-20p text-right table-end-item">Action</th>
          </tr>
        </thead>
        <tbody v-for="(leadProperty, index) in dataLists.property" :key="index">
          <tr v-if="leadProperty.type == '1'">
            <th scope="row" class="table-start-item">{{ ++index }}</th>
            <td>{{ leadProperty.title }}</td>
            <td>{{ leadProperty.percentage }}</td>
            <td><span v-if="leadProperty.status == 1">Active</span><span v-else>Inactive</span></td>
            <td class="text-right table-end-item">
              <a href="javascript:;" class="mr-3">
                <i class="fa fa-edit" @click="edit('LeadPriority', leadProperty.id)"></i>
              </a>
              <a href="javascript:;">
                <i class="fa fa-trash" @click="drop(leadProperty.id)"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <LeadProperty @parent-event="getData"/>
    <LeadSource @parent-event="getData"/>
    <LeadStatus @parent-event="getData"/>
    <LeadTodo @parent-event="getData"/>
  </div>
  <!-- tab-pane -->
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Services";
import LeadProperty from "./leadPriority";
import LeadSource from "./leadSource";
import LeadStatus from "./leadStatus";
import LeadTodo from "./leadTodo";
export default {
  components: {
      LeadProperty,
      LeadSource,
      LeadStatus,
      LeadTodo,
  },
  methods: {
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    // drop(id) {
    //   this.$store.commit("setDataId", id);
    //   this.$store.commit("dropRecord", `api/lead-property/${id}`);
    // },
     drop(id) {
      this.$swal({
       title: 'Do you really want to delete Lead Property ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true, 
        preConfirm:() => {
           return Services.deleteLeadProperty(id).then((response) => {
            if(response.status == 200){
                let success = response.data.success_message;
                this.setNotification(success);
            }
            this.getData();
          }).catch(error => { 
              if(error.response.status == 500){
                this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    getData(){
      //this.$store.commit("getData", `api/lead-property`);
      Services.getLeadProperty().then(res=>{
        this.$store.commit("setDataLists",res.data.data);
      }).catch(err=>{
        console.log(err);
      });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
      "currentComponent"
    ]),
  },
  watch: {
    currentComponent(value){
      if (value=='leadSetting'){this.getData();}
    },
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
    modalMode(value) {
      if (value == "create") {
          this.$store.commit("getData1", `api/lead/create`);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>