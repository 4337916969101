import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class TaxService{
    storeVatSetting(formData){
        const url = `api/settings/tax/store/vat`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    getExtraFields(){
        const url = `api/settings/tax`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createExtraFields(formData){
        const url = `api/settings/tax/extra-fields/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    updateExtraFields(id,formData){
        const url = `api/settings/tax/extra-fields/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
}
export default new TaxService();