<template>
  <div id="general" class="tab-pane active show">
    <h6 class="tx-uppercase tx-semibold tx-color-01 mg-b-0 word-space-4">
      General Setting
    </h6>

    <hr class="mg-t-4" />
    <div class="form-settings">
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Currency *</div>
        <div class="col-md-8 ml-0 pl-0">
          <select
            v-model="formData.currency"
            class="form-control"
          >
            <option
              v-for="(currency, index) in dataLists.currencies"
              :key="index"
              :value="currency.id"
            >
              {{ currency.title }}
            </option>
          </select>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Select Currency You want to Use for your Transactions.
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Date type *</div>
        <div class="col-md-8 ml-0 pl-0">
          <select
            v-model="formData.date_type"
            class="form-control"
          >
            <option value="ad">English Date (AD)</option>
          </select>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">Select Date Type.</div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Date Format *</div>
        <div class="col-md-8 ml-0 pl-0">
          <select
            v-model="formData.date_format"
            class="form-control"
          >
            <option value="Y/M/d">YYYY/MM/DD</option>
          </select>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">Select Date Format</div>
        </div>
      </div>
      <!-- row -->
      <!-- for Organization With Vehicle showroom and Service Center  -->
      <!-- Enable Vehicle Details -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Enable Vehicle Details</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="checkbox"
            v-model="formData.enable_vehicle_detail"
            id="depreciation"
          />
          <label for="depreciation" class="font-l ml-2 mb-0 pb-0 line-ht"
            >Enable</label
          >
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Enable this only if you own vehicle showroom and service Center, This option enables fields for Engine No, Chesis No, etc in Customer Section and also change Sales order as Job Card
          </div>
        </div>
      </div>
      <!-- <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">VAT</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="checkbox"
            @change="submit"
            v-model="formData.vatable"
            id="export"
          />
          <label for="export" class="font-l ml-2 mb-0 pb-0 line-ht"
            >Enable</label
          >
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Only Check VAT if your Company is in VAT.
          </div>
        </div>
      </div> -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Depreciation</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="checkbox"
            v-model="formData.depreciation"
            id="depreciation"
          />
          <label for="depreciation" class="font-l ml-2 mb-0 pb-0 line-ht"
            >Enable</label
          >
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Only check depreciation if you want to include the depreciation
            during the calculation.
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Reserve And Surplus</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="number"
            v-model="formData.reserve_surplus"
            id="reserveSurplus"
            min="0"
            step="any"
            class="form-control"
          />
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">Company's Profit/Loss till previous Year, for Loss fill with -ve Sign  </div>
        </div>
      </div>
      <div class="col-md-12 text-right mt-2 pr-0">
      <button @click="submit" class="btn btn-sm btn-primary">Submit</button>
    </div>
      <!-- row -->
    </div>
  </div>
  <!-- tab-pane -->
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        id: null,
        currency: null,
        date_type: null,
        date_format: null,
        vatable: 0,
        depreciation: 0,
        reserve_surplus: null,
        enable_vehicle_detail: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "eventMessage",
      "dataLists",
      "currentComponent",
      "authData",
    ]),
  },
  mounted() {
    this.$store.commit("setCurrentComponent", "generalSetting");
  },
  methods: {
    submit() {
      if (this.formData.id) {
        this.$store.commit(
          "setApiUrl",
          `api/settings/general/${this.formData.id}`
        );
        this.$store.commit("patchData", this.formData);
      } else {
        this.$store.commit("setApiUrl", "api/settings/general");
        this.$store.commit("sendData", this.formData);
      }
      // this.setAuthData(this.formData.vatable);
    },
    getData() {
      this.$store.commit("getData", "api/settings/general/create");
    },
  },
  watch: {
    currentComponent(value) {
      if (value == "generalSetting") {
        this.getData();
      }
    },
    eventMessage(value) {
      if (
        value.indexOf("created success") >= 0 &&
        this.currentComponent == "generalSetting"
      ) {
        this.getData();
      }
    },
    dataLists(value) {
      if (value.length != 0) {
        this.formData = { ...this.dataLists.data };
      }
    },
  },
  destroyed() {
    this.formData.id = null;
  },
};
</script>