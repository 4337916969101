<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Setting</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  System Settings
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">System Setting</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
    </div>
    <!---row --->
    <div class="row">
      <div class="col-md-4">
        <ul class="list-group list-group-settings">
          <li class="list-group-item list-group-item-action">
            <a
              href="#general"
              data-toggle="tab"
              @click="$store.commit('setCurrentComponent','generalSetting')"
              class="media active primary-text-color"
            >
              <i class="fas fa-cogs"></i>
              <div class="media-body">
                <h6>General Setting</h6>
                <span>Manage System Configuration</span>
              </div>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a
              href="#company"
              data-toggle="tab"
              @click="$store.commit('setCurrentComponent','companySetting')"
              class="media primary-text-color"
            >
              <i class="fas fa-user-cog"></i>
              <div class="media-body">
                <h6>Organization Setting</h6>
                <span>Manage Company Information</span>
              </div>
            </a>
          </li>
          <!-- <li class="list-group-item list-group-item-action">
            <a href="#securitySetting" @click="$store.commit('setCurrentComponent','securitySetting')" data-toggle="tab" class="media primary-text-color">
              <i class="fas fa-shield-alt"></i>
              <div class="media-body">
                <h6>Security Setting</h6>
                <span>Manage your security information</span>
              </div>
            </a>
          </li> -->
          <li class="list-group-item list-group-item-action">
            <a href="#item" @click="$store.commit('setCurrentComponent','itemSetting')" data-toggle="tab" class="media primary-text-color">
              <i class="fab fa-product-hunt"></i>
              <div class="media-body">
                <h6>Item Setting</h6>
                <span>Manage Item Configuration</span>
              </div>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a href="#purchase" @click="$store.commit('setCurrentComponent','purchaseSetting')" data-toggle="tab" class="media primary-text-color">
              <i class="fas fa-shopping-bag"></i>
              <div class="media-body">
                <h6>Purchase Setting</h6>
                <span>Manage Prchase Configuration</span>
              </div>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a href="#sales" @click="$store.commit('setCurrentComponent','salesSetting')" data-toggle="tab" class="media primary-text-color">
              <i class="fas fa-shopping-cart"></i>
              <div class="media-body">
                <h6>Sales Setting</h6>
                <span>Manage Sales Configuration</span>
              </div>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a href="#lead" @click="$store.commit('setCurrentComponent','leadSetting')" data-toggle="tab" class="media primary-text-color">
              <i class="fas fa-headset"></i>
              <div class="media-body">
                <h6>Lead Setting</h6>
                <span>Manage Lead Configuration</span>
              </div>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a href="#hrm" @click="$store.commit('setCurrentComponent','hrmSetting')" data-toggle="tab" class="media primary-text-color">
              <i class="fas fa-user"></i>
              <div class="media-body">
                <h6>HRM Setting</h6>
                <span>Manage Huanan Resource Setting</span>
              </div>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a href="#tax" @click="$store.commit('setCurrentComponent','taxSetting')" data-toggle="tab" class="media primary-text-color">
              <i class="fas fa-flag"></i>
              <div class="media-body">
                <h6>Tax Setting</h6>
                <span>Manage Tax Configuration</span>
              </div>
            </a>
          </li>
          <li class="list-group-item list-group-item-action">
            <a href="#yearChange" @click="$store.commit('setCurrentComponent','sessionYearSetting')" data-toggle="tab" class="media primary-text-color">
              <i class="fas fa-cogs"></i>
              <div class="media-body">
                <h6>Change Academic Year</h6>
                <span>Manage Academic Year Setting</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!-- col -->
      <div class="col-md-8">
        <div
          class="card card-body pd-sm-40 pd-md-30 pd-xl-y-35 pd-xl-x-40 bg-theam-secondary"
        >
          <div class="tab-content">
            <GeneralSetting />
            <CompanySetting />
            <ItemSetting />
            <PurchaseSetting />
            <SalesSetting />
            <SettingLead />
            <TaxSetting />
            <HrmSetting />
            <AcademicYear />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GeneralSetting from "./SettingModules/generalSetting";
import TaxSetting from "./SettingModules/taxSetting";
import CompanySetting from "./SettingModules/companySetting";
import ItemSetting from "../Item/Setting/index";
import PurchaseSetting from "../Purchase/Setting/index";
import SalesSetting from "../Sales/Setting/index";
import SettingLead from "../Lead/Setting/index";
import HrmSetting from "../HumanResource/Setting/index";
import AcademicYear from "./SettingModules/AcademicYearSrtting/index";
export default {
  components: {
    GeneralSetting,
    CompanySetting,
    ItemSetting,
    PurchaseSetting,
    SalesSetting,
    SettingLead,
    HrmSetting,
    TaxSetting,
    AcademicYear,
  },
};
</script>
<style scoped>
</style>