<template>
  <div id="hrm" class="tab-pane">
    <!-- <h6 class="tx-uppercase tx-semibold tx-color-01 mg-b-0 word-space-4">
      Employee Setting
    </h6>
    <hr class="mg-t-4" />
    <div class="form-settings">
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Prefix *</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" class="form-control" v-model="formData.prefix" />
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Prefix to be included before Employee ID
          </div>
        </div>
      </div> -->
    <!-- </div>
    <hr> -->
    <h6 class="tx-uppercase tx-semibold tx-color-01 mg-b-0 word-space-4">
      Attendance Setting
    </h6>
    <hr class="mg-t-4" />
    <div class="form-settings">
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">In Time *</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="time" class="form-control" v-model="formData.in_time" />
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Default Office Opening Time, Seduled time for employee to reach to
            Office
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">In Out *</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="time" class="form-control" v-model="formData.out_time" />
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Default Office Closing Time, Seduled time to leave office
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Time after Late *</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="time"
            class="form-control"
            v-model="formData.late_after"
          />
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Time After which attendance will be Marked, Counted and Calculated
            as Late
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Half Day for Late</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="checkbox"
            v-model="formData.half_day_late"
            id="half_day_late"
          />
          <label for="half_day_late" class="font-l ml-2 mb-0 pb-0 line-ht"
            >Enable</label
          >
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Enabling this all late attendance will be Calculated as Half day.
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Half day Timing</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="number"
            class="form-control"
            v-model="formData.half_day"
          />
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Below How Much woking time your Employee will be counted as Half
            day.
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- form-settings -->
    <div class="col-md-12 text-right mt-2 pr-0">
      <button type="button" @click="submit" class="btn btn-sm btn-primary">
        Submit
      </button>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-6">
        <h5 class="word-space-3 mt-1">Extra Field for Salary Master</h5>
      </div>
      <div class="col-sm-0 tx-right col-lg-6">
        <button
          type="button"
          class="btn btn-xs btn-primary mt-0 mr-0 mb-2"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'addMasterFields',
              mode: 'create',
            })
          "
        >
          Add Field
        </button>
      </div>
    </div>
    <div class="bg-theam-primary">
      <table class="table table1 table-hover" id="exampleTable">
        <thead>
          <tr>
            <th class="wd-5p table-start-item">S.N</th>
            <th class="wd-50p">Field Title</th>
            <th class="wd-15p">Type</th>
            <th class="wd-15p text-right table-end-item">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(field, index) in dataLists.extra_fields" :key="index">
            <th scope="row" class="table-start-item">{{ ++index }}</th>
            <td>{{ field.title }}</td>
            <td>{{ field.type == 0 ? "Additive" : "Deductive" }}</td>
            <td class="text-right table-end-item">
              <a href="javascript:;" class="mr-3">
                <i
                  class="fa fa-edit"
                  @click="edit('addMasterFields', field.id)"
                ></i>
              </a>
              <a href="javascript:;">
                <i class="fa fa-trash" @click="drop(field.id)"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddField @parent-event="getData"/>
  </div>
  <!-- tab-pane -->
</template>
<script>
import { mapGetters } from "vuex";
import AddField from "./AddMasterfield";
import Services from "../Services/Services";
export default {
  data() {
    return {
      formData: {
        id: null,
        prefix: null,
        in_time: null,
        out_time: null,
        late_after: null,
        half_day_late: 0,
        half_day: null,
      },
    };
  },
  components: { AddField },
  computed: {
    ...mapGetters(["eventMessage", "dataLists", "currentComponent"]),
  },
  methods: {
    submit() {
      if (this.formData.id) {
          Services.updateEmployeeSetting(this.formData, this.formData.id)
          .then((res) => {
            this.setNotification(res.data.success_message);
            this.$emit('parent-event');
          })
          .catch((err) => {
            this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
          });
      } else {
        Services.createEmployeeSetting(this.formData)
          .then((res) => {
            this.setNotification(res.data.success_message);
            this.$emit('parent-event');
          })
          .catch((err) => {
            this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
          });
      }
    },
    getData() {
      this.$store.commit("getData", "api/settings/employee");
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$swal({
       title: 'Do you really want to delete extra field ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true, 
        preConfirm:() => {
           return Services.deleteEmployeeExtraField(id).then((response) => {
            if(response.status == 200){
                let success = response.data.success_message;
                this.setNotification(success);
            }
            this.getData();
          }).catch(error => { 
              if(error.response.status == 500){
                this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },
  watch: {
    currentComponent(value) {
      if (value == "hrmSetting") {
        this.getData();
      }
    },
    // eventMessage(value){
    //   if (value.indexOf('success')>=0 && this.currentComponent=='hrmSetting'){this.getData();}
    // },
    dataLists(value) {
      if (value.length != 0) {
        this.formData = { ...this.dataLists.data };
      }
    },
  },
  destroyed() {
    this.formData.id = null;
  },
};
</script>