<template>
  <div id="tax" class="tab-pane" v-if="extrasLists">
    <h6 class="tx-uppercase tx-semibold tx-color-01 mg-b-0 word-space-4">
      Tax Setting
    </h6>
    <hr class="mg-t-4" />
    <div class="form-settings">
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">VAT *</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="checkbox"
            @change="changeBalance"
            id="vat"
            v-model="vat_data.vat"
            :disabled="edit_vatable"
          />
          <label for="vat" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check only if your company is vatable. You need to fill your Opening VAT BaLnce (VAT Payable or receiveable from TAX Office), Value with -ve sign if your company had tax receiveable from tax office
          </div>
        </div>
      </div>
      <div class="row mb-2" v-if="opening_visible">
        <div class="col-md-3 mr-0 pr-0 form-label">Opening Balance *</div>
        <div class="col-md-8 ml-0 pl-0">
          <input
            type="number"
            id="opening_balance"
            v-model="vat_data.opening_balance"
          />
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Opening Balance of Vat.
          </div>
        </div>
      </div>
      <div class="row mb-2 text-right" v-if="opening_visible">
        <div class="col-md-12 ml-3 pl-0">
          <button
            type="submit"
            @click="submitVat"
            class="btn btn-sm btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
      <!-- row -->
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h5 class="word-space-3 mt-1">Manage Tax Heads</h5>
      </div>
      <div class="col-sm-0 tx-right col-lg-6">
        <button
          type="button"
          class="btn btn-xs btn-primary mt-0 mr-0 mb-2"
          @click="create"
        >
          Add Tax Head
        </button>
      </div>
    </div>
    <div class="bg-theam-primary">
      <table class="table table1 table-hover" id="exampleTable">
        <thead>
          <tr>
            <th class="wd-5p table-start-item">S.N</th>
            <th class="wd-50p">Tax Title</th>
            <th class="wd-15p">Type</th>
            <th class="wd-15p text-right table-end-item">Action</th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr v-for="(field, index) in extrasLists" :key="index">
            <th scope="row" class="table-start-item">{{ ++index }}</th>
            <td>{{ field.title }}</td>
            <td>{{ (field.tax_type == 0) ? "Company Tax" : "Employee Tax" }}</td>
            <td class="text-right table-end-item">
              <a href="javascript:;" class="mr-3">
                <i
                  class="fa fa-edit"
                  @click="edit('addTaxFields', field.id)"
                ></i>
              </a>
              <a href="javascript:;">
                <i class="fa fa-trash" @click="drop(field.id)"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="loading">
          <tr>
            <td colspan="11" class="text-center">Loading...</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12">
      <div
        class="modal fade modal_cust show"
        v-if="modalId == 'addTaxFields'"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        style="display: block"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Add Tax Head
              </h5>
              <a class="close" @click="$store.dispatch('modalClose')">
                <span aria-hidden="true">×</span>
              </a>
            </div>
            <form
              @submit.prevent="submitExtraFields"
              class="bg-theam-secondary"
            >
              <div class="modal-body row">
                <div class="col-md-12 pd-r-7-5">
                  <div class="group">
                    <label class="group-label">Tax Details</label>
                    <div class="group-attribute-container">
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">Title *</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <input
                            type="input"
                            v-model="formData.title"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">Tax Type*</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <select
                            v-model="formData.tax_type"
                            class="form-control"
                            required
                          >
                            <!-- For Employee -->
                            <option value="1">Employee Tax</option> 
                            <!-- Not For Employee -->
                            <option value="0">Company Tax</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">Opening Balance*</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <input
                            type="number"
                            name="opening_balance"
                            id="opening_balance"
                            required  
                            v-model="formData.opening_balance"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-right mt-3">
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary"
                    :disabled="disableSubmitButton"
                  >
                    {{ disableSubmitButton ? "Submitting..." : "Submit" }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tab-pane -->
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Services";
export default {
  data() {
    return {
      vat_data: {
        vat: 0,
        opening_balance: null,
      },
      formData: {
        title: "",
        opening_balance: "",
        tax_type: 1,
      },
      opening_visible: false,
      edit_vatable: false,
      loading: false,
      disableSubmitButton: false,
    };
  },
  components: {},
  computed: {
    ...mapGetters([
      "eventMessage",
      "dataLists",
      "modalMode",
      "modalId",
      "authData",
      "currentComponent",
    ]),
    ...mapGetters("taxExtras", ["extrasLists"]),
  },
  beforeMount() {
    //console.log(this.authData);
    this.edit_vatable =
      this.authData.general_setting.vatable == 1 ? true : false;
    this.vat_data.vat = this.authData.general_setting.vatable;
  },
  methods: {
    changeBalance() {
      if (this.vat_data.vat == 1) {
        this.opening_visible = true;
      } else {
        this.opening_visible = false;
      }
    },
    edit(modal, id) {
      let details = this.extrasLists.find(function (data) {
        return data.id == id;
      });
      this.formData = { ...details };
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    create(){
        this.clearData();
        this.$store.dispatch('modalWithMode', {id: 'addTaxFields',mode: 'create',});
    },
    submitVat() {
      Services.storeVatSetting(this.vat_data)
        .then((res) => {
          this.setNotification(res.data.success_message);
          this.opening_visible = false;
          this.edit_vatable = true;
          this.getData();
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 422) {
            let error = Object.values(err.response.data.errors).flat();
            this.setNotification(error);
          } else {
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
        });
    },
    submitExtraFields() {
      this.disableSubmitButton = true;
      if (this.modalMode == "create") {
        Services.createExtraFields(this.formData)
          .then((res) => {
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
            this.getData();
          })
          .catch((err) => {
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              let error = err.response.data.error_message;
              this.setNotification(error);
            }
          })
          .finally(() => {
            this.disableSubmitButton = false;
          });
      } else {
        Services.updateExtraFields(this.formData.id,this.formData)
          .then((res) => {
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
            this.getData();
          })
          .catch((err) => {
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
                
              let error = err.response.data.error_message;
              this.setNotification(error);
            }
          })
          .finally(() => {
            this.disableSubmitButton = false;
          });
      }
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getData() {
      this.loading = true;
      Services.getExtraFields()
        .then((res) => {
          this.$store.commit("taxExtras/setExtrasLists", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearData(){
      this.formData.title="";
      this.formData.opening_balance = "";
      this.formData.tax_type = 1;
    }
  },
  watch: {
    currentComponent(value) {
      if (value == "taxSetting") {
        this.getData();
      }
    },
  },
  destroyed() {},
};
</script>