<template>
  <form @submit.prevent="submit" id="purchase" class="tab-pane">
    <h6 class="tx-uppercase tx-semibold tx-color-01 mg-b-0 word-space-4">
      Purchase Setting
    </h6>
    <hr class="mg-t-4" />
    <div class="form-settings">
      <!-- <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Printer Type</div>
        <div class="col-md-8 ml-0 pl-0">
          <select v-model="formData.printer_type" class="form-control">
              <option value="a4">A4 Sheet Printer</option>
              <option value="dotmatrix4inch">Dot Matrix printer 4 inch</option>
          </select>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Choose purchase slip printing Format.
          </div>
        </div>
      </div> -->
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Import</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox" id="export"  v-model="formData.export">
          <label for="export" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your Company import items or Products from foreign Country
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Shipping Detail</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox" id="shipping_details"  v-model="formData.shipping_details">
          <label for="shipping_details" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your Company need to include Transportation detail in purchase.
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">User Name</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox" id="user_name"  v-model="formData.user_name">
          <label for="user_name" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your Compnay need to display and print User name in Your Purchase Slip
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Prefix</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" class="form-control" v-model="formData.prefix" placeholder="Eg. #" />
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Prefix for Purchase Id, only fill if your company need to use Prifix before Purchase Id. keep blank if nothing needed.
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Description Note</div>
        <div class="col-md-8 ml-0 pl-0">
          <textarea v-model="formData.description" class="form-control" rows="3" placeholder="Write Something...."></textarea>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Write Default Note your Company need to Print in Your Purchase Slip, keep blank if nothing needed
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <div class="col-md-12 text-right mt-2 pr-0">
      <button type="submit" class="btn btn-sm btn-primary">Submit</button>
    </div>
  </form>
</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import Services from "../Services/PurchaseSetting";
export default {
  data(){
    return{
      formData:{
        'id':null,
        'printer_type':null,
        'export':0,
        'shipping_details':0,
        'user_name':0,
        'prefix':null,
        'description':null
      }
    }
  },
  computed: {
    ...mapGetters(["eventMessage","dataLists",'currentComponent']),
  },
  methods:{
    submit(){
      if(this.formData.id){
        // this.$store.commit("setApiUrl", `api/settings/purchase/${this.formData.id}`);
        // this.$store.commit("updateData", this.formData);
         Services.updatePurchaseSetting(this.formData,this.formData.id).then(res=>{
           this.setNotification(res.data.success_message);
        }).catch(err=>{
          this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      }else{
        // this.$store.commit("setApiUrl", "api/settings/purchase");
        // this.$store.commit("addData", this.formData);
        Services.createPurchaseSetting(this.formData,this.formData.id).then(res=>{
           this.setNotification(res.data.success_message);
        }).catch(err=>{
          this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      }
    },
    getData(){
      this.$store.commit("getData", "api/settings/purchase");
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },watch:{
    currentComponent(value){
      if (value=='purchaseSetting'){this.getData();}
    },
    eventMessage(value){
      if (value.indexOf('created success')>=0 && this.currentComponent=='purchaseSetting'){this.getData();}
    },
    dataLists(value){
      if (value.length!=0){
        this.formData={...this.dataLists};
      }
    }
  },destroyed() {
    this.formData.id=null;
  }
}
</script>