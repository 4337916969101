<template>
  <div class="col-md-12">
    <div class="modal fade modal_cust show" v-if="modalId == 'salesExtraFields'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add New Field</h5>
            <a class="close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="submit" class="bg-theam-secondary">
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="group">
                  <label class="group-label">Field Setting</label>
                  <div class="group-attribute-container">
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Title *</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <input type="input" v-model="formData.title" class="form-control" required/>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Field Type*</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <select v-model="formData.type" class="form-control" required>
                          <option value="0">Addition</option>
                          <option value="1">Deduction</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 mr-0 pr-0">VAT</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <select v-model="formData.vat" class="form-control" required>
                          <option value="1">VAT able</option>
                          <option value="0">Non Vatable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Services from "../Services/SalesSetting";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        'title':null,'type':0,'vat':0
      },
    };
  },
  computed: {
    ...mapGetters([
      "eventMessage",
      "dataLists",
      "modalId",
      "modalMode",
      "dataId",
    ]),
  },
  methods: {
    submit() {
      // if (this.modalMode == "create") {
      //   this.$store.commit("setApiUrl", "api/settings/sales/extra-field");
      //   this.$store.commit("addData", this.formData);
      // } else if (this.modalMode == "edit") {
      //   this.$store.commit("setApiUrl", `api/settings/sales/extra-field/${this.formData.id}`);
      //   this.$store.commit("updateData", this.formData);
      // }
      if (this.modalMode == "create") {
        Services.createExtraField(this.formData).then(res=>{
           this.setNotification(res.data.success_message);
           this.$emit('parent-event');
        }).catch(err=>{
          this.disableSubmitButton = false;
          console.log(err);
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      } else if (this.modalMode == "edit") {
         Services.updateExtraField(this.formData,this.formData.id).then(res=>{
           this.setNotification(res.data.success_message);
           this.$emit('parent-event');
        }).catch(err=>{
          this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      }
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },
  watch: {
    dataId(value) {
      if (this.modalId == 'salesExtraFields') {
        let details = this.dataLists.extra_fields.find(function (data) {
          return data.id == value;
        });
        this.formData={...details};
      }
    },
  },destroyed() {
    this.formData.id=null;
  }
};
</script>