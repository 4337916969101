<template>
<div id="item" class="tab-pane">
   <form @submit.prevent="submit" >
    <h6 class="tx-uppercase tx-semibold tx-color-01 mg-b-0 word-space-4">
      Item Setting
    </h6>
    <hr class="mg-t-4" />
    <div class="form-settings">
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Expiry Date</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox" id="expiry" v-model="formData.expiry">
          <label for="expiry" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your company need to Include Expiry Date for your Items.
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Barcode</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox" v-model="formData.barcode" id="barcode">
          <label for="barcode" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your Company need to use barcode for your Items.
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Extra Detail</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox" v-model="formData.extra_detail" id="extra_detail">
          <label for="extra_detail" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your Company need to use Extra Detail for your Items.
          </div>
        </div>
      </div>
      <!-- <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Prefix</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text" class="form-control" v-model="formData.prefix"/>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Prefix you need to include before your Item Id
          </div>
        </div>
      </div> -->
      <!-- row -->
    </div>
    <div class="col-md-12 text-right mt-2 pr-0 mb-2">
      <button type="submit" class="btn btn-sm btn-primary">Submit</button>
    </div>
    </form>
     <div class="row">
       <AddField />
      <div class="col-lg-6">
        <h5 class="word-space-4 mt-1">Manage Extra Field</h5>
      </div>
      <div class="col-sm-0 tx-right col-lg-6">
        <button type="button" class="btn btn-xs btn-primary mt-0 mr-0 mb-2"
        @click="$store.dispatch('modalWithMode', { id: 'itemFields', mode: 'create'})">
          Add Field
        </button>
      </div>
    </div>
    <div class="bg-theam-primary">
      <table class="table table1 table-hover" id="exampleTable">
        <thead>
          <tr>
            <th class="wd-5p table-start-item">S.N</th>
            <th class="wd-50p">Field Title</th>
            <th class="wd-15p">Status</th>
            <th class="wd-15p text-right table-end-item">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(field,index) in itemSettings.extra_fields" :key="index">
            <th scope="row" class="table-start-item">{{++index}}</th>
            <td>{{ field.title }}</td>
            <td>{{ field.status==0?'In-Active':'Active' }}</td>
            <td class="text-right table-end-item">
              <a href="javascript:;" class="mr-3">
                <i class="fa fa-edit" @click="edit('itemFields', field.id)"></i>
              </a>
              <a href="javascript:;">
                <i class="fa fa-trash" @click="drop(field.id)"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
        
    </div>
</div>
 
</template>

<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import AddField from "../Setting/NewField.vue";
import Services from "../Services/Services";
export default {
  data(){
    return{
      formData:{
        'id':null,
        'expiry':0,
        'barcode':0,
        'prefix':null,
        'extra_detail':0,
      }
    }
  },
  components:{
    AddField
  },
  computed: {
    ...mapGetters(["eventMessage","dataLists",'currentComponent']),
    ...mapGetters("items",["itemSettings"]),
  },
  methods:{
    submit(){
      if(this.formData.id){
        // this.$store.commit("setApiUrl", `api/settings/item/${this.formData.id}`);
        // this.$store.commit("updateData", this.formData);
        Services.updateItemSetting(this.formData,this.formData.id).then(res=>{
           this.setNotification(res.data.success_message);
           this.getData();
        }).catch(err=>{
          this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      }else{
        this.$store.commit("setApiUrl", "api/settings/item");
        this.$store.commit("addData", this.formData);
      }
    },
    getData(){
      // this.$store.commit("getData", "api/settings/item");
      Services.getSettings().then(res=>{
        this.$store.commit("items/setItemSettings",res.data);
         this.formData={...this.itemSettings.data};
      }); 
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
     drop(id) {
      this.$swal({
       title: 'Do you really want to delete extra field ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true, 
        preConfirm:() => {
           return Services.deleteExtraField(id).then((response) => {
            if(response.status == 200){
                let success = response.data.success_message;
                this.setNotification(success);
            }
            this.getData();
          }).catch(error => { 
              if(error.response.status == 500){
                this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },watch:{
    currentComponent(value){
      if (value=='itemSetting'){this.getData();}
    },
    eventMessage(value){
      if (value.indexOf('created success')>=0 && this.currentComponent=='itemSetting'){this.getData();}
    },
  }
}
</script>