<template>
  <div id="sales" class="tab-pane">
    <h6 class="tx-uppercase tx-semibold tx-color-01 mg-b-0 word-space-4">
      Sales Setting
    </h6>
    <hr class="mg-t-4" />
    <div class="form-settings">
      <!-- <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Printer Type</div>
        <div class="col-md-8 ml-0 pl-0">
          <select v-model="formData.printer_type" @change="submit" class="form-control">
              <option value="a4">A4 Sheet Printer</option>
              <option value="dotmatrix4inch">Dot Matrix printer 4 inch</option>
          </select>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Choose sales slip printing Format.
          </div>
        </div>
      </div> -->
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Export</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox"  v-model="formData.export" id="export">
          <label for="export" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your company Export Items / Products to foreign Country
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Shipping Detail</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox"  v-model="formData.shipping_details" id="shipping_details">
          <label for="shipping_details" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your compnay need to Include Transportation detail in sales bills.
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">User Name</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox"  v-model="formData.user_name" id="user_name">
          <label for="user_name" class="font-l ml-2 mb-0 pb-0 line-ht">Enable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if your company need to display and print username in Sales Bills
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Prefix</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="text"  class="form-control" v-model="formData.prefix" placeholder="Eg. #"/>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Prefix for Bill No, only fill if your company need to use Prifix before Bill No. keep blank if nothing needed.
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Item List Design *</div>
        <div class="col-md-8 ml-0 pl-0">
          <select class="form-control" v-model="formData.item_selection_design">
              <option value="0">Table Listing</option>
              <option value="1">List with Image</option>
          </select>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Choose List with Image Listing if you wish to list items with Imges while Selecting item in Sales Module.
          </div>
        </div>
      </div>
      <!-- row -->
      <!-- <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Sales Rate</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox" @change="submit" v-model="formData.sales_rate" id="sales_rate">
          <label for="sales_rate" class="font-l ml-2 mb-0 pb-0 line-ht">Editable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if you want to your Sales Rate to be editable during Sales bill
          </div>
        </div>
      </div> -->
      <!-- row -->
      <!-- <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Discount</div>
        <div class="col-md-8 ml-0 pl-0">
          <input type="checkbox" @change="submit" v-model="formData.discount" id="discount">
          <label for="discount" class="font-l ml-2 mb-0 pb-0 line-ht">Editable</label>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Check if you want to your Sales Discount to be editable during Sales bill
          </div>
        </div>
      </div> -->
      
      <!-- row -->
      <div class="row mb-2">
        <div class="col-md-3 mr-0 pr-0 form-label">Description Note</div>
        <div class="col-md-8 ml-0 pl-0">
          <textarea @blur="submit" v-model="formData.description" class="form-control" rows="3" placeholder="Write Something...."></textarea>
          <div class="tx-11 tx-sans tx-color-04 mg-t-4">
            Write Default Note your Company need to Print in Your Sales Bill, keep blank if nothing needed
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right mt-2 pr-0">
      <button @click="submit" class="btn btn-sm btn-primary">Submit</button>
    </div>
          <!-- row -->
    </div>
    <hr>
    <div class="row">
      <div class="col-lg-6">
        <h5 class="word-space-4 mt-1">Manage Extra Field</h5>
      </div>
      <div class="col-sm-0 tx-right col-lg-6">
        <button type="button" class="btn btn-xs btn-primary mt-0 mr-0 mb-2"
        @click="$store.dispatch('modalWithMode', { id: 'salesExtraFields', mode: 'create'})">
          Add Field
        </button>
      </div>
    </div>
    <div class="bg-theam-primary">
      <table class="table table1 table-hover" id="exampleTable">
        <thead>
          <tr>
            <th class="wd-5p table-start-item">S.N</th>
            <th class="wd-50p">Field Title</th>
            <th class="wd-15p">Type</th>
            <th class="wd-15p">VAT</th>
            <th class="wd-15p text-right table-end-item">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(field,index) in dataLists.extra_fields" :key="index">
            <th scope="row" class="table-start-item">{{++index}}</th>
            <td>{{ field.title }}</td>
            <td>{{ field.type==0?'Addictive':'Subtractive' }}</td>
            <td>{{ field.vat==0?'Non Vatable':'Vatable' }}</td>
            <td class="text-right table-end-item">
              <a href="javascript:;" class="mr-3">
                <i class="fa fa-edit" @click="edit('salesExtraFields', field.id)"></i>
              </a>
              <a href="javascript:;">
                <i class="fa fa-trash" @click="drop(field.id)"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddField />
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import AddField from "./NewField";
import Services from "../Services/SalesSetting";
export default {
  data(){
    return{
      formData:{
        'id':null,
        'printer_type':null,
        'export':0,
        'shipping_details':0,
        'prefix':null,
        'sales_rate':0,
        'discount':0,
        'user_name':0,
        'description':null
      }
    }
  },components: { AddField },
  computed: {
    ...mapGetters(["eventMessage","dataLists",'currentComponent']),
  },
  methods:{
    submit(){
      if(this.formData.id){
        // this.$store.commit("setApiUrl", `api/settings/sales/${this.formData.id}`);
        // this.$store.commit("patchData", this.formData);
        Services.updateSalesSetting(this.formData,this.formData.id).then(res=>{
           this.setNotification(res.data.success_message);
        }).catch(err=>{
          this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      }else{
        // this.$store.commit("setApiUrl", "api/settings/sales");
        // this.$store.commit("sendData", this.formData);
        Services.createSalesSetting(this.formData,this.formData.id).then(res=>{
           this.setNotification(res.data.success_message);
        }).catch(err=>{
          this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              this.setNotification(err.response.data.error_message);
            }
        });
      }
    },
    getData(){
      this.$store.commit("getData", "api/settings/sales");
    },edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/settings/sales/extra-field/${id}`);
    },
    drop(id) {
      this.$swal({
       title: 'Do you really want to delete extra field ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true, 
        preConfirm:() => {
           return Services.deleteExtraField(id).then((response) => {
            if(response.status == 200){
                let success = response.data.success_message;
                this.setNotification(success);
            }
            this.getData();
          }).catch(error => { 
              if(error.response.status == 500){
                this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },watch:{
    currentComponent(value){
      if (value=='salesSetting'){this.getData();}
    },
    eventMessage(value){
      if (value.indexOf('success')>=0 && this.currentComponent=='salesSetting'){this.getData();}
    },
    dataLists(value){
      if (value.length!=0){
        this.formData={...this.dataLists.data};
      }
    }
  }
};
</script>